import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import LoginVisitor from "./LoginVisitor";
import { BACKEND_PATH, CMS_PATH } from "./config";
import { DirectusData } from "./Types/Directus";
import Notification from "./Components/Notification";

interface Props {
  directusData: DirectusData;
}

const Initial = ({ directusData }: Props) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [invite] = useSearchParams();

  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isFastLogin, setIsFastLogin] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const support_chat = async () => {
    let request = await fetch(`${BACKEND_PATH}/landing/1`);
    let response = (await request.json()).landings[0];
    response.support_chat && window.smartsupp("chat:show");
  };

  useEffect(() => {
    setTimeout(() => {
      support_chat();
    }, 1000);
  }, [window.smartsupp]);

  function handleChangeEmail(event: any) {
    setEmail(event.target.value);
  }

  function submit(event: React.SyntheticEvent) {
    event.preventDefault();
    fetch(`${BACKEND_PATH}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Exclusiverse-Lang": i18n.language.slice(0, 2).toUpperCase(),
      },
      body: JSON.stringify({
        email: email,
        invite: invite.get("invite") || false,
      }),
    })
      .then(async (response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("SEND_EMAIL"), {
            autoHideDuration: 10000,
            variant: "success",
          });

          response.json().then((body) => {
            console.log(body);
            //window.open(body.token, "_self");
          });
        } else if (response.status === 403) {
          response.json().then((body) => {
            setIsFastLogin(true);
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("ERROR_LOGIN"), {
          autoHideDuration: 5000,
          variant: "error",
        });
      });
  }

  return (
    <>
      <div className="md:grid md:grid-cols-2 h-screen">
        <div className="hidden md:block">
          <img
            alt=""
            className="h-screen object-cover w-auto"
            src={
              directusData.background == null || directusData.background === ""
                ? "./images/backPC.jpg"
                : CMS_PATH + "/assets/" + directusData.background
            }
          />
        </div>

        <Notification />

        <div className="flex flex-col h-full mx-auto w-full md:w-2/3 justify-center md:justify-around px-6 md:px-20">
          {isLogin ? (
            isFastLogin ? (
              <>
                <LoginVisitor
                  setViewForm={setIsFastLogin}
                  emailText={email}
                  directusData={directusData}
                />
              </>
            ) : (
              <>
                <div>
                  <h3 className="font-bold text-[#190134] bg-gradient-to-r from-pink-600 to-blue-500 text-transparent bg-clip-text text-4xl mt-3">
                    {t("ACCESS_EXCLUSIVE")}
                  </h3>
                  <p className="my-4">{t("ACCESS_SUBTITLE_EXCLUSIVE")}</p>
                  <form onSubmit={submit} className="w-full  mt-3">
                    <label className="block font-bold text-base text-[#190134] my-2">
                      {t("EMAIL")}
                    </label>
                    <input
                      onChange={handleChangeEmail}
                      autoComplete="off"
                      id="email"
                      type="email"
                      className="border border-[#B3ABBC] rounded-full py-2 px-3 w-full outline-none"
                    />

                    <div className=" w-full flex justify-center">
                      <button
                        onClick={submit}
                        disabled={email.length > 0 ? false : true}
                        style={{
                          color:
                            directusData.color_buttons == null ||
                              directusData.color_buttons === ""
                              ? "#FF2773"
                              : directusData.color_buttons,
                          borderColor:
                            directusData.color_buttons == null ||
                              directusData.color_buttons === ""
                              ? "#FF2773"
                              : directusData.color_buttons,
                          background:
                            email.length > 0
                              ? directusData.color_buttons == null ||
                                directusData.color_buttons === ""
                                ? "#FF2773"
                                : directusData.color_buttons
                              : "#B3ABBC",
                        }}
                        className={`mt-10 w-full flex flex-row justify-center items-center gap-2 rounded-full px-3 py-2 text-white font-semibold`}
                      >
                        <p className="text-white">{t("ENTER")}</p>
                        <span>
                          <svg
                            className=""
                            width="32"
                            height="33"
                            viewBox="0 0 32 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.7071 6.99699C16.3166 6.60647 15.6834 6.60647 15.2929 6.99699C14.9024 7.38752 14.9024 8.02068 15.2929 8.41121L22.5858 15.7041H7C6.44772 15.7041 6 16.1518 6 16.7041C6 17.2564 6.44772 17.7041 7 17.7041H22.5858L15.2929 24.997C14.9024 25.3875 14.9024 26.0207 15.2929 26.4112C15.6834 26.8017 16.3166 26.8017 16.7071 26.4112L25.7071 17.4112C26.0976 17.0207 26.0976 16.3875 25.7071 15.997L16.7071 6.99699Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>

                {/* <div className=" w-full flex justify-middle flex-row mt-12 md:mt-0 items-center gap-2">
                  <p className="font-bold text-[#685879]">
                    {t("NOT_MEMBER_YET")}
                  </p>
                  <button
                    onClick={() =>
                      window.open(
                        "https://www.exclusiverse.club/sign-up?origin-url=https://xp.exclusiverse.club"
                      )
                    }
                    className="flex items-center text-[#685879] font-semibold"
                  >
                    {t("CREATE_SORT")}
                    <span>
                      <svg
                        className="ml-2"
                        width="32"
                        height="33"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.7071 6.99699C16.3166 6.60647 15.6834 6.60647 15.2929 6.99699C14.9024 7.38752 14.9024 8.02068 15.2929 8.41121L22.5858 15.7041H7C6.44772 15.7041 6 16.1518 6 16.7041C6 17.2564 6.44772 17.7041 7 17.7041H22.5858L15.2929 24.997C14.9024 25.3875 14.9024 26.0207 15.2929 26.4112C15.6834 26.8017 16.3166 26.8017 16.7071 26.4112L25.7071 17.4112C26.0976 17.0207 26.0976 16.3875 25.7071 15.997L16.7071 6.99699Z"
                          fill="#685879 "
                        />
                      </svg>
                    </span>
                  </button>
                </div> */}
              </>
            )
          ) : (
            <>
              <div>
                {directusData.logo_upper_text == null ||
                  directusData.logo_upper_text === "" ? (
                  <h1 className="text-[#797979] text-xl font-normal">
                    {t("THIS_IS")}
                  </h1>
                ) : (
                  <img
                    className="h-[32px] object-scale-down	"
                    src={CMS_PATH + "/assets/" + directusData.logo_upper_text}
                    alt=""
                  />
                )}

                <h1 className="font-bold text-black text-5xl mt-3">
                  {t("WELCOME")}
                </h1>
                <svg
                  className="mt-5"
                  width="30"
                  height="2"
                  viewBox="0 0 30 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1H30"
                    stroke={
                      directusData.color_buttons == null ||
                        directusData.color_buttons === ""
                        ? "#FF2773"
                        : directusData.color_buttons
                    }
                    strokeWidth="2"
                  />
                </svg>
                <p className=" mt-5">{t("IN_EXCLUSIVERSE")}</p>

                <div className="flex flex-col gap-6 mt-8">
                  <button
                    onClick={() => setIsLogin(true)}
                    style={{
                      background:
                        directusData.color_buttons == null ||
                          directusData.color_buttons === ""
                          ? "#FF2773"
                          : directusData.color_buttons,
                    }}
                    className="text-white bg-[#] md:text-base rounded-full px-7 py-3 shadow-[0px_4px_20px_rgba(0,0,0,0.25)]"
                  >
                    {t("MEMBER")}
                  </button>
                </div>
              </div>
              {/*
              <div className="flex mt-12 items-center gap-2 md:gap-6">
                <p className="text-[#685879] "> {t("NOT_MEMBER_YET")}</p>

                 <button
                  onClick={() => {
                    //@ts-ignore
                    window.open(
                      "https://www.exclusiverse.club/sign-up?origin-url=https://xp.exclusiverse.club"
                    );
                  }}
                  className="flex items-center text-[#685879] font-semibold"
                >
                  {t("CREATE_SORT")}
                  <span>
                    <svg
                      className="ml-2"
                      width="32"
                      height="33"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.7071 6.99699C16.3166 6.60647 15.6834 6.60647 15.2929 6.99699C14.9024 7.38752 14.9024 8.02068 15.2929 8.41121L22.5858 15.7041H7C6.44772 15.7041 6 16.1518 6 16.7041C6 17.2564 6.44772 17.7041 7 17.7041H22.5858L15.2929 24.997C14.9024 25.3875 14.9024 26.0207 15.2929 26.4112C15.6834 26.8017 16.3166 26.8017 16.7071 26.4112L25.7071 17.4112C26.0976 17.0207 26.0976 16.3875 25.7071 15.997L16.7071 6.99699Z"
                        fill="#685879"
                      />
                    </svg>
                  </span>
                </button> 
              </div>
              */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Initial;
