import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { BACKEND_PATH } from "./config";
import { DirectusData } from "./Types/Directus";
import { Checkbox } from "@mui/material";

interface Props {
  directusData: DirectusData;
}
const Login = ({ directusData }: Props) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  function handleChangeEmail(event: any) {
    setEmail(event.target.value);
  }

  function submit(event: React.SyntheticEvent) {
    event.preventDefault();
    fetch(`${BACKEND_PATH}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Exclusiverse-Lang": i18n.language,
      },
      body: JSON.stringify({ email: email }),
    })
      .then(async (response) => {
        console.log(response);

        if (response.status === 200) {
          enqueueSnackbar(t("SEND_EMAIL"), {
            autoHideDuration: 10000,
            variant: "success",
          });
        } else if (response.status === 401) {
          enqueueSnackbar(t("ERROR_LOGIN"), {
            autoHideDuration: 5000,
            variant: "error",
          });
          response.json().then((body) => {
            console.log(body);
          });
        } else if (response.status === 403) {
          enqueueSnackbar(t("ERROR_LOGIN"), {
            autoHideDuration: 5000,
            variant: "error",
          });
          response.json().then((body) => {
            console.log(body);
          });
        } else {
          enqueueSnackbar(t("ERROR_LOGIN"), {
            autoHideDuration: 5000,
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("ERROR_LOGIN"), {
          autoHideDuration: 5000,
          variant: "error",
        });
      });
  }

  return (
    <div className="flex flex-col justify-center items-center mt-10">
      <img src="./images/iconExclusiverseBlack.svg" alt="" />
      <h1 className="font-semibold text-xl text-[#190134] mt-10">
        {t("ACCESS_EXCLUSIVE")}
      </h1>
      <form onSubmit={submit} className="w-3/4 md:w-1/3 mt-16">
        <label className="block font-semibold text-base text-[#190134]">
          {t("EMAIL")}
        </label>
        <input
          onChange={handleChangeEmail}
          autoComplete="off"
          id="email"
          type="email"
          className="border border-[#B3ABBC] rounded-full py-2 px-3 w-full outline-none"
        />

        <button
          onClick={submit}
          style={{
            color:
              directusData.color_buttons == null ||
              directusData.color_buttons === ""
                ? "#FF2773"
                : directusData.color_buttons,
            borderColor:
              directusData.color_buttons == null ||
              directusData.color_buttons === ""
                ? "#FF2773"
                : directusData.color_buttons,
            background:
              email.length > 0
                ? directusData.color_buttons == null ||
                  directusData.color_buttons === ""
                  ? "#FF2773"
                  : directusData.color_buttons
                : "#B3ABBC",
          }}
          className={`mt-10 w-full rounded-full p-3 text-white font-semibold `}
        >
          {t("ENTER")}
        </button>
      </form>
    </div>
  );
};
export default Login;
