import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { SnackbarProvider } from "notistack";

declare global {
  interface Window {
    smartsupp: any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

let isMobile = false;

function handleWindowSizeChange() {
  isMobile = window.innerWidth <= 768;
}

handleWindowSizeChange();

window.addEventListener("resize", handleWindowSizeChange);

root.render(
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: isMobile ? "top" : "bottom",
        horizontal: isMobile ? "center" : "right",
      }}
    >
      <App />
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
