import { MainInterface } from "./App";
import { useLocation, useNavigate } from "react-router-dom";
import type { Location } from "@remix-run/router";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { DirectusData } from "./Types/Directus";
import { CMS_PATH } from "./config";

interface Props {
  directusData: DirectusData;
  showBack: boolean;
}

const Header = ({ showBack, directusData }: Props) => {
  const { i18n } = useTranslation();

  return (
    <>
      <div
        className="w-full absolute top-0 left-0 flex justify-between items-center px-10 py-10 md:py-6 md:bg-none  bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage:
            window.innerWidth < window.innerHeight
              ? "url(./images/backgroundHeaderMobile.png)"
              : "",
        }}
      >
        <div className="flex gap-4 md:gap-[10vw] items-start ">
          <img
            src="./images/exclusiverseWhite.svg"
            className="w-1/2 md:w-auto"
          />
          {directusData.logo_upper == "" || directusData.logo_upper == null ? (
            <p></p>
          ) : (
            <img
              className="h-[32px] md:h-[64px] object-scale-down"
              src={CMS_PATH + "/assets/" + directusData.logo_upper!}
            />
          )}
        </div>
        <div className="flex flex-row gap-1 text-white md:text-[#B3ABBC]">
          <p
            onClick={() => i18n.changeLanguage("es-ES")}
            style={{
              color:
                i18n.language === "es-ES"
                  ? directusData.color_buttons == null ||
                    directusData.color_buttons == ""
                    ? "#FF2773"
                    : directusData.color_buttons
                  : "",
              borderColor:
                i18n.language === "es-ES"
                  ? directusData.color_buttons == null ||
                    directusData.color_buttons == ""
                    ? "#FF2773"
                    : directusData.color_buttons
                  : "",
            }}
            className={`cursor-pointer ${
              i18n.language === "es-ES"
                ? `border-b-2 `
                : `border-b-2 border-transparent`
            }`}
          >
            ES
          </p>
          <span>·</span>
          <p
            onClick={() => i18n.changeLanguage("en-US")}
            style={{
              color:
                i18n.language === "en-US"
                  ? directusData.color_buttons == null ||
                    directusData.color_buttons === ""
                    ? "#FF2773"
                    : directusData.color_buttons
                  : "",
              borderColor:
                i18n.language === "en-US"
                  ? directusData.color_buttons == null ||
                    directusData.color_buttons === ""
                    ? "#FF2773"
                    : directusData.color_buttons
                  : "",
            }}
            className={`cursor-pointer ${
              i18n.language === "en-US"
                ? "border-b-2 border-[#FF2773] text-[#FF2773]"
                : "border-b-2 border-transparent"
            }`}
          >
            EN
          </p>
          <span>·</span>
          <p
            onClick={() => i18n.changeLanguage("fr-FR")}
            style={{
              color:
                i18n.language === "fr-FR"
                  ? directusData.color_buttons == null ||
                    directusData.color_buttons === ""
                    ? "#FF2773"
                    : directusData.color_buttons
                  : "",
              borderColor:
                i18n.language === "fr-FR"
                  ? directusData.color_buttons == null ||
                    directusData.color_buttons == ""
                    ? "#FF2773"
                    : directusData.color_buttons
                  : "",
            }}
            className={`cursor-pointer ${
              i18n.language === "fr-FR"
                ? "border-b-2 "
                : "border-b-2 border-transparent"
            }`}
          >
            FR
          </p>
        </div>
      </div>
    </>
  );
};

export default Header;
