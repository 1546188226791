import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { BACKEND_PATH } from "./config";
import { useSearchParams } from "react-router-dom";
import { DirectusData } from "./Types/Directus";

interface Props {
  setViewForm: Function;
  emailText: string;
  directusData: DirectusData;
}

const LoginVisitor = ({ setViewForm, emailText, directusData }: Props) => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [hasRead, sethasRead] = useState<boolean>(false);
  const [hasConditions, sethasConditions] = useState<boolean>(false);

  const [invite] = useSearchParams();

  const [waitingForResponse, setWaitingForResponse] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  function handleChangeName(event: any) {
    setName(event.target.value);
  }

  function handleChangeSurname(event: any) {
    setSurname(event.target.value);
  }

  function handleHasRead(event: any) {
    sethasRead(!hasRead);
  }

  function handleHasConditions(event: any) {
    sethasConditions(!hasConditions);
  }

  function submitAllLogin(event: React.SyntheticEvent) {
    event.preventDefault();

    if (
      emailText.length < 0 ||
      name.length < 0 ||
      surname.length < 0 ||
      !hasRead || !hasConditions
    ) {
      enqueueSnackbar(t("ERROR_LOGIN"), {
        autoHideDuration: 5000,
        variant: "error",
      });

      return;
    }

    setWaitingForResponse(true);

    fetch(`${BACKEND_PATH}/createFastAcc`, {
      //TODO
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Exclusiverse-Lang": i18n.language.slice(0, 2).toUpperCase(),
      },
      body: JSON.stringify({
        email: emailText,
        name: name,
        surname: surname,
        invite: invite.get("invite") || false,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("SEND_EMAIL"), {
            autoHideDuration: 10000,
            variant: "success",
          });

          setWaitingForResponse(false);

          response.json().then((body) => {
            window.open(body.token, "_self");
          });
        } else if (response.status === 400) {
          (document.getElementById("name") as HTMLInputElement).value = "";
          (document.getElementById("surname") as HTMLInputElement).value = "";

          // setMustCreateAccount(false);

          //Display Error
          enqueueSnackbar(t("ERROR_LOGIN"), {
            autoHideDuration: 5000,
            variant: "error",
          });

          setWaitingForResponse(false);
        } else if (response.status === 400) {
          (document.getElementById("name") as HTMLInputElement).value = "";
          (document.getElementById("surname") as HTMLInputElement).value = "";

          // setMustCreateAccount(false);

          //Display Error
          enqueueSnackbar(t("ERROR_LOGIN"), {
            autoHideDuration: 5000,
            variant: "error",
          });
        }
        setWaitingForResponse(false);
      })
      .catch((error) => {
        (document.getElementById("name") as HTMLInputElement).value = "";
        (document.getElementById("surname") as HTMLInputElement).value = "";

        //Display Error
        enqueueSnackbar(t("ERROR_LOGIN"), {
          autoHideDuration: 5000,
          variant: "error",
        });
        setWaitingForResponse(false);
      });
  }

  return (
    <div className={`flex flex-col justify-center  mt-10`}>
      <h1 className="font-bold text-4xl w-full text-[#190134] bg-gradient-to-r from-pink-600 to-blue-500 text-transparent mt-10 bg-clip-text">
        {t("NEW_VISITOR")}
      </h1>

      <div className="flex w-full items-center justify-start mt-2 gap-6">
        <h2 className="font-bold text-black text-md">{emailText}</h2>

        <button
          onClick={() => {
            setViewForm(false);
          }}
          className="text-[#FF2773] font-sm"
        >
          {t("CHANGE")}
        </button>
      </div>

      <>
        <p className="mt-6">{t("NEW_VISITOR_SUBTITLE")}</p>
        <form
          onSubmit={submitAllLogin}
          className="w-full  mt-8  flex flex-col md:gap-4 gap-6"
        >
          <div>
            <label className="block font-semibold text-base text-[#190134]">
              {t("NAME")}
            </label>
            <input
              onChange={handleChangeName}
              autoComplete="off"
              id="name"
              type="name"
              className={`border border-[#B3ABBC] rounded-full py-2 px-3 w-full outline-none  ${waitingForResponse && "text-[#B3ABBC]"
                }`}
            />
          </div>
          <div>
            <label className="block font-semibold text-base text-[#190134]">
              {t("SURNAME")}
            </label>
            <input
              onChange={handleChangeSurname}
              autoComplete="off"
              id="surname"
              type="surname"
              className={`border border-[#B3ABBC] rounded-full py-2 px-3 w-full outline-none ${waitingForResponse && "text-[#B3ABBC]"
                }`}
            />
          </div>

          <div className="flex gap-3">
            <input type="checkbox" onChange={handleHasRead}></input>
            <p>
              {t("TERMS_1")}
              <a
                className={`underline text-[${directusData.color_buttons}]`}
                href={directusData.termsLink!}
                target="_blank"
              >
                {t("TERMS_2")}
              </a>
            </p>
          </div>
          <div className="flex gap-3">
            <input type="checkbox" onChange={handleHasConditions}></input>
            <p>
              {t("TERMS_3")}
              <a
                className={`underline text-[${directusData.color_buttons}]`}
                href={directusData.termsConditions!}
                target="_blank"
              >
                {t("TERMS_4")}
              </a>
            </p>
          </div>
          {waitingForResponse ? (
            <div
              id="divLoading"
              className=" w-full flex items-center justify-center "
            >
              <svg
                className="animate-spin"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40 20C40 24.1504 38.7088 28.1981 36.3053 31.5818C33.9018 34.9655 30.5052 37.5176 26.5863 38.8844C22.6674 40.2512 18.4203 40.3649 14.4338 39.2098C10.4474 38.0547 6.91904 35.688 4.33792 32.4378C1.75681 29.1876 0.250796 25.2149 0.0286646 21.0704C-0.193467 16.9259 0.879286 12.815 3.09821 9.30751C5.31713 5.80002 8.5723 3.06974 12.4125 1.49516C16.2526 -0.0794199 20.4875 -0.420294 24.5301 0.519792L23.474 5.06119C20.3738 4.34027 17.1262 4.60168 14.1813 5.80918C11.2364 7.01667 8.74013 9.11045 7.03851 11.8002C5.33688 14.49 4.51422 17.6426 4.68456 20.8209C4.85491 23.9991 6.00983 27.0457 7.98921 29.5382C9.96859 32.0307 12.6744 33.8457 15.7315 34.7315C18.7886 35.6173 22.0455 35.5301 25.0508 34.4819C28.0561 33.4337 30.6609 31.4766 32.5041 28.8817C34.3472 26.2868 35.3374 23.1828 35.3374 20H40Z"
                  fill="url(#paint0_angular_702_11384)"
                />
                <defs>
                  <radialGradient
                    id="paint0_angular_702_11384"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(20 20) rotate(-75.4998) scale(18.7213 17.4018)"
                  >
                    <stop stopColor="#E5007E" />
                    <stop
                      offset="0.997738"
                      stopColor="#E5007E"
                      stopOpacity="0"
                    />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          ) : (
            <button
              onClick={submitAllLogin}
              style={{
                background:
                  emailText.length > 0 &&
                    name.length > 0 &&
                    surname.length > 0 &&
                    hasRead && hasConditions
                    ? directusData.color_buttons === null ||
                      directusData.color_buttons === ""
                      ? "#FF2773"
                      : directusData.color_buttons
                    : "#B3ABBC",
              }}
              className={`mt-10 w-full md:w-[70%] md:mx-auto rounded-full p-3 text-white font-semibold flex items-center justify-center gap-6 `}
            >
              {t("CREATE")}

              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.2071 2.29289C12.8166 1.90237 12.1834 1.90237 11.7929 2.29289C11.4024 2.68342 11.4024 3.31658 11.7929 3.70711L19.0858 11H3.5C2.94772 11 2.5 11.4477 2.5 12C2.5 12.5523 2.94772 13 3.5 13H19.0858L11.7929 20.2929C11.4024 20.6834 11.4024 21.3166 11.7929 21.7071C12.1834 22.0976 12.8166 22.0976 13.2071 21.7071L22.2071 12.7071C22.5976 12.3166 22.5976 11.6834 22.2071 11.2929L13.2071 2.29289Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          )}
        </form>
      </>
      {/*
      <div className="flex flex-col items-center justify-center">
        <svg
          className="mt-5"
          width="30"
          height="2"
          viewBox="0 0 30 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 1H30"
            stroke={
              directusData.color_buttons === null ||
              directusData.color_buttons === ""
                ? "#FF2773"
                : directusData.color_buttons
            }
            strokeWidth="2"
          />
        </svg>
         <div className="mt-4 flex flex-row items-center gap-2">
          <p
            className={` text-[#685879] ${waitingForResponse && "opacity-30"}`}
          >
            {t("NOT_MEMBER_YET")}
          </p>
          <button
            onClick={() =>
              window.open(
                "https://www.exclusiverse.club/sign-up?origin-url=https://xp.exclusiverse.club"
              )
            }
            className={`flex items-center text-[#685879] font-semibold ${
              waitingForResponse && "opacity-30"
            }`}
          >
            {t("CREATE_SORT")}
            <span>
              <svg
                className="ml-2"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.7071 6.99699C16.3166 6.60647 15.6834 6.60647 15.2929 6.99699C14.9024 7.38752 14.9024 8.02068 15.2929 8.41121L22.5858 15.7041H7C6.44772 15.7041 6 16.1518 6 16.7041C6 17.2564 6.44772 17.7041 7 17.7041H22.5858L15.2929 24.997C14.9024 25.3875 14.9024 26.0207 15.2929 26.4112C15.6834 26.8017 16.3166 26.8017 16.7071 26.4112L25.7071 17.4112C26.0976 17.0207 26.0976 16.3875 25.7071 15.997L16.7071 6.99699Z"
                  fill={
                    directusData.color_buttons == null ||
                    directusData.color_buttons === ""
                      ? "#FF2773"
                      : directusData.color_buttons
                  }
                />
              </svg>
            </span>
          </button>
        </div> 
      </div>
      */}
    </div>
  );
};
export default LoginVisitor;
