import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Header from "./Header";
import Initial from "./Initial";
import Login from "./Login";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DirectusData } from "./Types/Directus";
import { CMS_PATH } from "./config";

export interface MainInterface {
  showBack: boolean;
}

function App() {
  const { t } = useTranslation();

  const [directusData, setData] = useState<DirectusData>({
    logo_upper: "",
    logo_upper_text: "",
    background: "",
    color_buttons: "",
    termsLink: "",
    termsConditions: "",
  });

  useEffect(() => {
    getDataFromDirectus();
    //@ts-ignore
    window._paq.push(["setDocumentTitle", `${document.title}`]);
    //@ts-ignore
    window._paq.push(["setCustomDimension", 1, "guest"]);
    //@ts-ignore
    window._paq.push(["trackPageView"]);
  }, []);

  function getDataFromDirectus() {
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };
    console.log("CMS", CMS_PATH)
    fetch(`${CMS_PATH}/items/landing/1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let data: DirectusData = {
          logo_upper: result.data.logo_upper,
          logo_upper_text: result.data.logo_upper_text,
          background: result.data.background,
          color_buttons: result.data.color_buttons,
          termsLink: result.data.terms_link,
          termsConditions: result.data.conditions_link,
        };

        setData(data);
      })
      .catch((error) => console.log("error", error));
  }

  return (
    <div>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText={t("ACCEPT")}
        declineButtonText={t("DECLINE")}
        cookieName="mtm_cookie_consent"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "white",
          background:
            directusData.color_buttons == null ||
              directusData.color_buttons === ""
              ? "#FF2773"
              : directusData.color_buttons,
          fontSize: "13px",
        }}
        declineButtonStyle={{
          color: "white",
          fontSize: "13px",
        }}
        expires={150}
      >
        {t("COOKIE_MSG")}
      </CookieConsent>
      <Router>
        <Header showBack={false} directusData={directusData} />
        <Routes>
          <Route path="/" element={<Initial directusData={directusData} />} />
          <Route
            path="/login"
            element={<Login directusData={directusData} />}
          />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
