import { useEffect, useState } from "react";
import { News } from "../Types/News";
import { useTranslation } from "react-i18next";
import { BACKEND_PATH, CMS_PATH } from "../config";

export default function Notification() {
  const [notifications, setNotifications] = useState<News[]>([]);
  const [notificationIndex, setNotificationIndex] = useState<number>(0);
  const [displayNotifications, setDisplayNotifications] =
    useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BACKEND_PATH + "/landing/news", requestOptions)
      .then((result) => {
        if (result.status == 200) return result.json();
      })
      .then((result) => {
        let lastNotificationSeen = -1;
        if (localStorage.getItem("notificationSeen")) {
          lastNotificationSeen = Number.parseInt(
            localStorage.getItem("notificationSeen")!
          );
        }

        let news = result.landingNews.filter((element: News) => {
          return element.id > lastNotificationSeen;
        }) as News[];

        if (news.length > 0) {
          setNotifications(news);
          setDisplayNotifications(true);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const nextNew = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    console.log("next New");

    setNotificationIndex((prevState) => prevState + 1);
  };

  const prevNew = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    console.log("Prev New");
    let newState = notificationIndex - 1;
    if (newState < 0) {
      newState = 0;
    }

    setNotificationIndex(newState);
  };

  const endNew = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    setDisplayNotifications(false);

    localStorage.setItem(
      "notificationSeen",
      notifications[notificationIndex].id.toString()
    );
  };

  return (
    <>
      {displayNotifications && (
        <div className=" absolute w-full h-full top-0 flex items-center justify-center bg-black bg-opacity-50 ">
          <div className="w-full m-4 h-[80%] md:w-[600px] md:max-h-[700px] md:h-[80%] flex flex-col items-start gap-4 p-8 bg-white rounded-md">
            <img
              alt=""
              className="w-full max-h-[40%] object-scale-down"
              id="Image"
              src={`${CMS_PATH}/assets/${notifications[notificationIndex].image}`}
            />

            <div className="h-[90%] space-y-2">
              <p
                className="text-[#797979] text-xl font-semibold"
                id="ModalTitle"
              >
                {t("SOON")}
              </p>

              <p className="text-[#190134] text-3xl font-bold" id="Title">
                {notifications[notificationIndex].title}
              </p>

              <p id="Description" className="w-[90%]">
                {notifications[notificationIndex].description}
              </p>
            </div>
            <div className="relative bottom-2 flex items-center h-[52px] w-full justify-between">
              {notifications.length > 1 && (
                <>
                  <button
                    id="PreviousButton"
                    className={`rounded-full px-4 py-2 flex items-center justify-center gap-2 ${
                      notificationIndex == 0 && "opacity-50"
                    }`}
                    onClick={prevNew}
                  >
                    <img
                      alt=""
                      src="https://cms-exclusiverse.imascono.com/assets/d7471a62-d650-42aa-8d2f-a76d7520a9b1"
                      className="rotate-180"
                    />
                    <p id="PreviousButtonText" className="text-[#FF2773]">
                      {t("PREVIOUS")}
                    </p>
                  </button>
                  <div
                    id="NewsIndicator"
                    className="relative -translate-y-[40px] md:translate-y-[0px] flex items-center justify-center gap-2"
                  >
                    {notifications.map((e, i) => {
                      return (
                        <div
                          className={`w-[8px] h-[8px] rounded-full ${
                            notificationIndex === i
                              ? "bg-[#E5007E]"
                              : "bg-[#D9D9D9]"
                          }`}
                        ></div>
                      );
                    })}
                  </div>

                  {notifications.length - 1 !== notificationIndex && (
                    <button
                      id="NextButton"
                      className="border-[2px] border-[#FF2773] rounded-full px-4 py-2 flex items-center justify-center gap-2 "
                      onClick={nextNew}
                    >
                      <p id="NextButtonText" className="text-[#FF2773]">
                        {t("NEXT")}
                      </p>

                      <img
                        alt=""
                        src="https://cms-exclusiverse.imascono.com/assets/d7471a62-d650-42aa-8d2f-a76d7520a9b1"
                      />
                    </button>
                  )}
                </>
              )}

              {notifications.length - 1 === notificationIndex && (
                <>
                  <button
                    className="border-[2px] border-[#FF2773] bg-[#FF2773] h-[52px] rounded-full px-4 py-2 flex items-center justify-center gap-2 "
                    onClick={endNew}
                  >
                    <p id="EndButtonText" className="text-white">
                      {t("UNDERSTOOD")}
                    </p>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
