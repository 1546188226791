import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    supportedLngs: ["en-US", "es-ES", "fr-FR"],
    resources: {
      "es-ES": {
        translation: {
          BACK: "Atrás",
          CREATE: "Crear",
          MEMBER: "Acceder",
          CREATE_LONG: "Activar tu cuenta",
          ENTER_AS_GUEST: "Entrar como invitado/a",
          CONTINUE: "Continuar",
          WELCOME_TO_A: "Bienvenido a un",
          NEW_EXCLUSIVE: "nuevo mundo",
          WORLD: "Exclusive",
          ACCESS_EXCLUSIVE: "Acceso usuarios",
          ACCESS_SUBTITLE_EXCLUSIVE:
            "Introduce tu email para recibir el acceso al espacio virtual.",
          NAME: "Nombre",
          SURNAME: "Apellidos",
          EMAIL: "Email",
          PASS: "Contraseña",
          ENTER: "Solicitar acceso",
          ERROR_LOGIN: "No se ha podido acceder. Inténtalo de nuevo",
          SEND_EMAIL:
            "Visita tu correo, te hemos enviado el acceso a Exclusiverse",
          WELCOME: "Bienvenido a una experiencia virtual única",
          THIS_IS: "ESTO ES EXCLUSIVERSE",
          IN_EXCLUSIVERSE:
            "En Exclusiverse podrás disfrutar de distintos espacios y vivir en primera persona una experiencia de viaje única en el mundo.",
          NOT_MEMBER_YET: "¿Tienes membresía NFT?",
          CREATE_SORT: "Actívala aquí",
          COOKIE_MSG:
            "Este sitio web utiliza cookies para mejorar la experiencia del usuario.",
          ACCEPT: "Aceptar",
          DECLINE: "Rechazar",
          CHANGE: "Cambiar",
          NEW_VISITOR: "Nuevo/a visitante",
          NEW_VISITOR_SUBTITLE:
            "Completa con tu nombre y apellidos para entrar por primera vez.",
          ERROR_LOGIN_403:
            "Dirección de correo desconocida, prueba acceder como visitante",
          ERROR_LOGIN_401: "No se ha podido acceder. Inténtalo de nuevo",
          SOON: "Próximamente",
          UNDERSTOOD: "Entendido",
          NEXT: "Siguiente",
          PREVIOUS: "Anterior",
          TERMS_1: "He leido y acepto los ",
          TERMS_2: "terminos y condiciones",
          TERMS_3: "He leido y acepto las ",
          TERMS_4: "condiciones del contrato",
        },
      },
      "en-US": {
        translation: {
          BACK: "Back",
          CREATE: "Create",
          CREATE_LONG: "Activate NFT membership",
          MEMBER: "Enter",
          ENTER_AS_GUEST: "Login as visitor",
          CONTINUE: "Continue",
          WELCOME_TO_A: "Welcome to a",
          NEW_EXCLUSIVE: "new world",
          WORLD: "Exclusive",
          ACCESS_EXCLUSIVE: "User access",
          ACCESS_SUBTITLE_EXCLUSIVE:
            "Enter your email to receive access to the virtual space.",
          NAME: "Name",
          SURNAME: "Surname",
          EMAIL: "Email",
          PASS: "Password",
          ENTER: "Request access",
          ERROR_LOGIN: "The email and/or password are incorrect",
          SEND_EMAIL:
            "Visit your email, we have sent you the access to Exclusiverse",
          WELCOME: "Welcome to a unique virtual experience",
          THIS_IS: "THIS IS EXCLUSIVERSE",
          IN_EXCLUSIVERSE:
            "In Exclusiverse you will be able to enjoy different spaces and live in first person a unique travel experience in the world.",
          NOT_MEMBER_YET: "Do you have NFT membership?",
          CREATE_SORT: "Activate it here",
          COOKIE_MSG:
            "This website uses cookies to enhance the user experience.",
          ACCEPT: "Accept",
          DECLINE: "Decline",
          NEW_VISITOR: "New visitor",
          CHANGE: "Change",
          NEW_VISITOR_SUBTITLE:
            "Fill in your name and surname to log in for the first time.",
          ERROR_LOGIN_403: "Unknown email address, try logging in as visitor",
          ERROR_LOGIN_401: "Unknown email address, try logging in as visitor",
          SOON: "Coming soon",
          UNDERSTOOD: "Understood",
          NEXT: "Next",
          PREVIOUS: "Previous",
          TERMS_1: "I have read and accept the ",
          TERMS_2: "terms and conditions",
          TERMS_3: "I have read and accept the ",
          TERMS_4: "contract conditions",
        },
      },
      "fr-FR": {
        translation: {
          BACK: "Derrière",
          CREATE: "Créer",
          MEMBER: "Accès",
          CREATE_LONG: "Activer l'adhésion à la NFT",
          ENTER_AS_GUEST: "Se connecter comme visiteur",
          CONTINUE: "Continuer",
          WELCOME_TO_A: "Bienvenue dans un",
          NEW_EXCLUSIVE: "nouveau monde",
          WORLD: "Exclusive",
          ACCESS_EXCLUSIVE: "Accès des usagers",
          ACCESS_SUBTITLE_EXCLUSIVE:
            "Entrez votre email pour recevoir l'accès à l'espace virtuel.",
          NAME: "Prénom",
          SURNAME: "Nom",
          EMAIL: "Courriel",
          PASS: "Mot de passe",
          ENTER: "Demande d'accès",
          ERROR_LOGIN: "L'email et/ou le mot de passe sont incorrects",
          SEND_EMAIL:
            "Consultez votre email, nous vous avons envoyé l'accès à Exclusiverse",
          WELCOME: "Bienvenue dans une expérience virtuelle unique",
          THIS_IS: "C'EST EXCLUSIVERSE",
          IN_EXCLUSIVERSE:
            "Chez Exclusiverse, vous pourrez profiter de différents espaces et vivre une expérience de voyage unique au monde.",
          NOT_MEMBER_YET: "Avez-vous une adhésion à la NFT ?",
          CREATE_SORT: "Activez-le ici",
          COOKIE_MSG:
            "Ce site web utilise des cookies pour améliorer l'expérience de l'utilisateur.",
          ACCEPT: "Accepter",
          DECLINE: "Refuser",
          CHANGE: "Changer",
          NEW_VISITOR: "Nouveau visiteur",
          NEW_VISITOR_SUBTITLE:
            "Remplissez votre nom et prénom pour vous connecter pour la première fois.",
          ERROR_LOGIN_403:
            "Adresse email inconnue, essayez de vous connecter en tant que visiteur",
          ERROR_LOGIN_401:
            "Adresse email inconnue, essayez de vous connecter en tant que visiteur",
          SOON: "Bientôt disponible",
          UNDERSTOOD: "Compris",
          PREVIOUS: "Précédent",
          NEXT: "Suivant",
          TERMS_1: "J'ai lu et j'accepte les ",
          TERMS_2: "conditions générales",
          TERMS_3: "J'ai lu et j'accepte les ",
          TERMS_4: "conditions du contrat",
        },
      },
    },
    // lng: "es", // if you're using a language detector, do not define the lng option
    fallbackLng: "es-ES",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
